import React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
      <path
        fill="#129D51"
        d="M9.048 21c4.996 0 9.047 4.03 9.047 9s-4.05 9-9.047 9C4.05 39 0 34.97 0 30s4.05-9 9.048-9zm41.904 0C55.95 21 60 25.03 60 30s-4.05 9-9.048 9c-4.996 0-9.047-4.03-9.047-9s4.05-9 9.047-9zM30 21c4.997 0 9.048 4.03 9.048 9S34.997 39 30 39s-9.048-4.03-9.048-9 4.051-9 9.048-9zM9.048 23.842c-3.42 0-6.19 2.757-6.19 6.158 0 3.4 2.77 6.158 6.19 6.158 3.419 0 6.19-2.757 6.19-6.158 0-3.4-2.771-6.158-6.19-6.158zm41.904 0c-3.419 0-6.19 2.757-6.19 6.158 0 3.4 2.771 6.158 6.19 6.158 3.42 0 6.19-2.757 6.19-6.158 0-3.4-2.77-6.158-6.19-6.158zm-20.952 0c-3.419 0-6.19 2.757-6.19 6.158 0 3.4 2.771 6.158 6.19 6.158 3.419 0 6.19-2.757 6.19-6.158 0-3.4-2.771-6.158-6.19-6.158z"
      />
    </svg>
  )
}

export default SvgComponent
